.b-swatches_sizes.b-length_border {
    display: flex;
}

.b-swatches_sizes.b-extended-size-select-tabs {
    .swiper-slide {
        width: 50%;

        .b-extended-size-select-tabs__button {
            width: 100%;
            border: none;
            border-bottom: 0.125rem solid #d0d0d0;
            border-radius: 0;

            &.m-active.selected {
                font-weight: bold;
                border: 0 none;
                border-bottom: 0.25rem solid #dc0019;
            }
        }
    }
}
