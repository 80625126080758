.b-product {
    &-quickview {
        .b-swatches_circle-link {
            margin: 0.4375rem;
        }
    }
}

.g-modal-dialog {
    &:not(.quick-add-dialog) {
        .b-product-quickview {
            .b-swatches_circle {
                $swatchH: 55px;
                $gap: 5px;
                $rows: 2;

                display: flex;
                gap: $gap;
                overflow: hidden;
                max-height: #{$swatchH * $rows + $gap * ($rows - 1)};

                @include respond-to(desktop) {
                    $rows: 2;
                    $swatchH: 55px;

                    max-height: #{$swatchH * $rows + $gap * ($rows - 1)};
                }

                .b-swatches_circle-item {
                    margin: 0;
                }
            }

            .b-product_attribute {
                &.m-color {
                    .b-color-more_less {
                        align-items: center;
                        margin-left: auto;
                        float: none;
                        display: block;
                        padding-top: 0.5rem;
                        min-height: auto;
                        top: auto;
                    }

                    .l-show-more,
                    .l-show-less {
                        text-decoration: underline;
                        border: none;
                        background: transparent;
                    }

                    &.show-all {
                        .b-swatches_circle {
                            max-height: unset;
                        }

                        .l-show-more {
                            display: none;
                        }

                        .l-show-less {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
}
