.b-cart-content {
    .b-cart_checkoutcontinue {
        .b-cart_paypalExpressButton {
            padding-top: rem-calc(16);
        }

        @include respond-to(mobile) {
            .b-cart_paypalExpressButton {
                padding: 0;
                padding-right: rem-calc(16);
                flex: 1;
                order: 2;

                div {
                    padding: 0;
                    width: auto;
                }
            }

            .order-summary_items {
                display: flex;
                justify-content: space-between;
                margin-bottom: 0;
            }
        }
    }

    .b-cart_checkoutbuttons {
        @include respond-to(mobile) {
            text-align: center;
            display: flex;
        }
    }
}
