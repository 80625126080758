.phoneNumberField {
    .nocountry {
        padding: 0.8rem;
        color: $red;
    }

    .iti {
        width: 100%;
        margin-top: 0.5rem;
    }
}
