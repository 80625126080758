.b-tile {
    &-gift-container {
        display: flex;
        flex-direction: column;
    }

    &-swatches {
        opacity: 1;
        width: 5.8rem;

        @include respond-to(desktop) {
            position: relative;
            margin-top: rem-calc(-12);
            bottom: 0;
            width: 100%;
        }

        @include respond-to(mobile) {
            display: flex;
            padding: 0;
        }

        & &_slider {
            margin-left: -5px;
        }

        &.hide-mobile {
            @include respond-to(mobile) {
                display: none;
            }
        }

        &.hide-desktop {
            @include respond-to(desktop) {
                display: none;
            }
        }

        @include respond-to(mobile) {
            .b-swatches_circle-item {
                flex: 0 0 auto;
            }

            .b-swatches_circle-link {
                margin: calc(spacing(xs2) / 2) 0;
            }
        }

        .b-swatches_circle-link {
            margin: 0;

            @include respond-to(desktop-large) {
                margin: calc(spacing(xs2) / 2);
            }

            @include respond-to(mobile) {
                margin: calc(spacing(xs2) / 2);
                width: rem-calc(20);
                height: rem-calc(20);
                padding: 0;
            }

            &::before {
                border-radius: 0.25rem;
                width: 1.35rem;
                height: 1.35rem;
            }

            &:active,
            &.m-active {
                box-shadow: none;
                border-color: transparent;

                @include respond-to(mobile) {
                    margin: 0.25rem;
                }

                &:hover {
                    border-color: $black;
                }
            }
        }
    }

    &-swatches_container {
        display: flex;
        align-items: center;
        order: 1;

        @include respond-to(mobile) {
            order: 3;
        }
    }

    &-badge-emea {
        order: 2;
        margin: 0;

        @include respond-to(mobile) {
            order: 1;
            margin: 0.5rem 0 0;
        }
    }

    &-name {
        order: 3;

        @include respond-to(mobile) {
            margin-top: 0.75rem;
            order: 2;
        }
    }

    &-subheader {
        order: 3;

        @include respond-to(mobile) {
            order: 2;
        }
    }

    &-swatches_count {
        display: none;

        @include respond-to(mobile) {
            padding: 0;
            display: block;
        }
    }

    &-swatches_slider_button {
        top: 60%;
    }

    &-info {
        .plp-outofstock,
        .gift-outofstock {
            @include font(main_med, rem-calc(12), 1.67);

            color: $green;
        }

        .b-price {
            order: 4;
            @include respond-to(mobile) {
                margin: 0;
            }
        }
    }
}

.b-tile-emea,
.b-tile-constructorio {
    @include respond-to (mobile) {
        .b-tile-swatches {
            .swiper-container.b-tile-swatches_slider {
                width: 100%;
            }
        }
    }
}
