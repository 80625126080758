.b-product_attrs {
    .b-swatches_circle-link {
        width: 2.45rem;
        height: 2.45rem;

        &::before {
            width: 2.45rem;
            height: 2.45rem;
        }

        &.m-disabled {
            &::after {
                width: 3.2rem;
                max-width: 3.2rem;
            }
        }
    }
}
