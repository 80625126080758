.b-navigation_banner {
    &-container {
        padding-right: rem-calc(40);
        padding-left: rem-calc(40);

        @include respond-to(desktop-large) {
            padding-right: rem-calc(60);
            padding-left: rem-calc(60);
        }
    }

    &-image {
        display: block;
    }

    &-title {
        margin-top: rem-calc(16);
    }

    &-link {
        margin-top: rem-calc(6);
        font-size: rem-calc(12);

        @include respond-to(desktop-large) {
            margin-top: rem-calc(8);
        }
    }
}

.b-header-banner {
    overflow: hidden;

    @include respond-to(desktop) {
        max-width: -webkit-fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content;
    }

    .promo-banner__wrapper {
        border: none;
        height: 40px;
        width: 100%;
    }

    .promo-tooltip-link {
        display: block;
        @include respond-to(mobile) {
            height: 100%;
        }

        .promo-tooltip-link {
            display: inline;
            color: $grey4;
        }
    }

    .promo-desktop-layout {
        > .promo-tooltip-link,
        > p,
        > a {
            padding: 10px 0;
            display: block;
            white-space: nowrap;
        }

        a {
            color: $white;
            text-decoration: none;
        }
    }

    .promo-pseudo-link {
        color: $grey4;
        text-decoration: underline;

        &:hover {
            color: $white;
        }
    }
    @include respond-to(mobile) {
        .promo-desktop-layout {
            display: none;
        }

        .js-promo-header-banner {
            text-align: center;
            position: absolute;
            width: 100%;
            height: 100%;
            line-height: 1;
            opacity: 0;
            left: 100%;
            bottom: 0;

            .promo-mobile-layout {
                > .promo-tooltip-link,
                > p,
                > a {
                    padding: 15px 0;
                    display: block;
                    white-space: nowrap;
                }
            }
        }

        [data-assets='1'] {
            .js-promo-header-banner {
                opacity: 1;
                left: initial;
            }
        }

        [data-assets='2'] {
            .js-promo-header-banner:first-child,
            .js-promo-header-banner:nth-child(2) {
                animation: promoTransition2 12s linear infinite;
            }

            .js-promo-header-banner:first-child {
                animation-delay: 0s;
            }

            .js-promo-header-banner:nth-child(2) {
                animation-delay: 6s;
            }
            @keyframes promoTransition2 {
                0% {
                    opacity: 0;
                    left: 100%;
                }

                5% {
                    opacity: 1;
                    left: 0;
                }

                45% {
                    opacity: 1;
                    left: 0;
                }

                50% {
                    opacity: 0;
                    left: -100%;
                }

                50.1% {
                    opacity: 0;
                    left: 100%;
                }
            }
        }

        [data-assets='3'] {
            .js-promo-header-banner:first-child,
            .js-promo-header-banner:nth-child(2),
            .js-promo-header-banner:nth-child(3) {
                animation: promoTransition3 18s linear infinite;
            }

            .js-promo-header-banner:first-child {
                animation-delay: 0s;
            }

            .js-promo-header-banner:nth-child(2) {
                animation-delay: 6s;
            }

            .js-promo-header-banner:nth-child(3) {
                animation-delay: 12s;
            }
            @keyframes promoTransition3 {
                0% {
                    opacity: 0;
                    left: 100%;
                }

                4% {
                    opacity: 1;
                    left: 0;
                }

                31% {
                    opacity: 1;
                    left: 0;
                }

                33.3% {
                    opacity: 0;
                    left: -100%;
                }

                33.4% {
                    opacity: 0;
                    left: 100%;
                }
            }
        }

        [data-assets='4'] {
            .js-promo-header-banner:first-child,
            .js-promo-header-banner:nth-child(2),
            .js-promo-header-banner:nth-child(3),
            .js-promo-header-banner:nth-child(4) {
                animation: promoTransition4 24s linear infinite;
            }

            .js-promo-header-banner:first-child {
                animation-delay: 0s;
            }

            .js-promo-header-banner:nth-child(2) {
                animation-delay: 6s;
            }

            .js-promo-header-banner:nth-child(3) {
                animation-delay: 12s;
            }

            .js-promo-header-banner:nth-child(4) {
                animation-delay: 18s;
            }

            @keyframes promoTransition4 {
                0% {
                    opacity: 0;
                    left: 100%;
                }

                3% {
                    opacity: 1;
                    left: 0;
                }

                23% {
                    opacity: 1;
                    left: 0;
                }

                25% {
                    opacity: 0;
                    left: -100%;
                }

                26% {
                    opacity: 0;
                    left: 100%;
                }
            }
        }

        [data-assets='5'] {
            .js-promo-header-banner:first-child,
            .js-promo-header-banner:nth-child(2),
            .js-promo-header-banner:nth-child(3),
            .js-promo-header-banner:nth-child(4),
            .js-promo-header-banner:nth-child(5) {
                animation: promoTransition5 30s linear infinite;
            }

            .js-promo-header-banner:first-child {
                animation-delay: 0s;
            }

            .js-promo-header-banner:nth-child(2) {
                animation-delay: 6s;
            }

            .js-promo-header-banner:nth-child(3) {
                animation-delay: 12s;
            }

            .js-promo-header-banner:nth-child(4) {
                animation-delay: 18s;
            }

            .js-promo-header-banner:nth-child(5) {
                animation-delay: 24s;
            }

            @keyframes promoTransition5 {
                0% {
                    opacity: 0;
                    left: 100%;
                }

                2% {
                    opacity: 1;
                    left: 0;
                }

                18% {
                    opacity: 1;
                    left: 0;
                }

                20% {
                    opacity: 0;
                    left: -100%;
                }

                21% {
                    opacity: 0;
                    left: 100%;
                }
            }
        }
    }
}

.mobile-messages__wrapper {
    > div {
        position: absolute;
        width: 100%;
        left: 0;
        background: $black;

        > * {
            padding: 14px;
            display: block;
            color: $white;
            text-decoration: none;
        }
    }

    .header-mobile-promo {
        z-index: 10;
    }

    .b-navigation-close {
        position: absolute;
        right: 1.25%;
        top: 6px;

        &::before,
        &::after {
            background-color: $white;
        }
    }
}

.b-global-message-banner__wrapper,
.b-global-promo-banner {
    left: 0;
    width: 100%;
    background-color: $black;
    color: #fff;
    text-align: center;
    border-bottom: 1px solid #fff;
    border-top: 1px solid #fff;

    a,
    .promo-tooltip-link {
        text-decoration: underline;
        cursor: pointer;
        font-weight: normal;
        color: $grey4;

        &:hover {
            color: $white;
        }
    }

    .promo-pseudo-link {
        color: $grey4;
        text-decoration: underline;
    }

    .promo-desktop-layout {
        padding: 19px 0;
        display: block;

        a,
        p {
            white-space: nowrap;
            color: $white;
        }
        @include respond-to(mobile) {
            display: none;
        }
    }
    @include respond-to(mobile) {
        display: none;
    }

    .b-global-promo-banner-message {
        width: 100%;
        display: block;
    }

    .b-global-promo-banner-exit {
        position: absolute;
        display: inline-block;
        z-index: 2;
        width: 4%;
        right: 0;
        padding-top: 20px;

        .b-navigation {
            &-close {
                position: relative;
                width: 1rem;

                &_button {
                    @include closeIcon(right, rem-calc(16));

                    position: absolute;
                    padding: rem-calc(24) !important;
                    left: -18px;
                    top: -22px;

                    &::before,
                    &::after {
                        top: rem-calc(22);
                        left: rem-calc(18);
                    }
                }
            }
        }
    }
}

.promo-banner--closed {
    margin-top: -60px;
    transition: margin-top 0.33s ease-out;
}

.b-global-promo-banner.promo-banner--closed + .b-global-message-banner__wrapper {
    margin-top: 0;
    transition: margin-top 0.33s ease-out;
}

.b-global-message-banner__wrapper + .b-global-promo-banner {
    position: absolute;
    z-index: 100;
    top: 106px;
}

.g-promo-combo-modal {
    color: $white !important;
    background-color: $black;
    position: absolute;
    z-index: 4;
    width: 288px;
    left: 50%;
    transform: translate(-50%, 12px);
    display: none;
    padding: 20px 16px 24px;
    border-radius: 1px;

    button {
        border: none;
    }

    .b-navigation-close {
        display: none;
    }

    .g-combo-modal-header {
        font-weight: 700;
        font-size: rem-calc(14);
        line-height: 1.43;
        margin-bottom: 12px;
        @include respond-to(mobile) {
            font-size: rem-calc(16);
        }
    }

    .g-combo-modal-body {
        padding-top: 8px;
        font-size: rem-calc(12);
        font-weight: normal;
        line-height: 1.67;
        @include respond-to(mobile) {
            font-size: rem-calc(14);
        }
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 100%;
        transform: translate(-50%, 0);
        left: 50%;
        border-color: transparent transparent $black transparent;
        border-style: solid;
        border-width: 8px;
    }
    @include respond-to(mobile) {
        color: $black !important;
        background-color: $white;
        width: 100%;
        height: 100vh;
        z-index: 110;
        padding: 56px 16px 32px;
        top: 0;
        left: 0;
        bottom: 0;
        border-radius: 0;
        transform: none;
        text-align: left;

        .g-combo-modal-header {
            margin-bottom: 12px;
        }

        .b-navigation-close {
            display: inline-block;
            position: absolute;
            right: 16px;
            top: 16px;
            width: 3rem;
            height: 3rem;

            &::before,
            &::after {
                width: 32px;
                left: 8px;
                top: 50%;
            }
        }

        .g-combo-modal-body {
            font-size: rem-calc(16);
        }
    }
}

.promo-banner-slider {
    height: 100%;
    position: relative;
    width: 100%;
}

.promo-mobile-layout,
.promo-banner--show {
    display: none;
    @include respond-to(mobile) {
        display: block;
    }
}
